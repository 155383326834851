import React from 'react';
import LiveImg from '../assets/img/liveShows.jpg';

const Live = () => {
    return (
        <div className='flex justify-center items-center'>
            <img
                className='w-[1230px]'
                src={LiveImg} />
        </div>
    )
}

export default Live