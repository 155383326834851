import React from 'react'

const Newsletter = () => {
  return (
    <div class="container  mx-auto">

      <section class="mb-5">
        <div
          class="bg-purple-400 alert alert-dismissible fade show rounded-lg py-4 px-6 text-white md:flex justify-between items-center text-center md:text-left">
          <div class="mb-4 md:mb-0 flex items-center flex-wrap justify-center md:justify-start">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-4 h-4 mr-2">
              <path fill="currentColor"
                d="M216 23.86c0-23.8-30.65-32.77-44.15-13.04C48 191.85 224 200 224 288c0 35.63-29.11 64.46-64.85 63.99-35.17-.45-63.15-29.77-63.15-64.94v-85.51c0-21.7-26.47-32.23-41.43-16.5C27.8 213.16 0 261.33 0 320c0 105.87 86.13 192 192 192s192-86.13 192-192c0-170.29-168-193-168-296.14z" />
            </svg>
            <strong class="mr-1">Audition now!</strong> Are you the next MiniPopStar?
          </div>
          <div class="flex items-center justify-center">
            <a class="inline-block px-6 py-2.5 bg-white text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-100 hover:shadow-lg focus:bg-gray-100 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-200 active:shadow-lg transition duration-150 ease-in-out mr-4"
              href="/details" role="button" data-mdb-ripple="true" data-mdb-ripple-color="light">Audition now</a>
          </div>
        </div>
      </section>

    </div>

  )
}

export default Newsletter