import React from 'react';
import Logo from '../assets/img/mps.jpg';
import { footer } from '../data';

const Footer = () => {
  return (
    <footer className='section bg-black text-white'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row justify-between border-b border-opacity-75 border-white pb-7 lg:pb-14 mb-14'>
          <a className='font text-2xl font-bold tracking-tight text-white sm:text-4xl' href='/'>
            <img
            className='w-[250px] pb-10'
            src={Logo} alt="" />
          </a>
          <div className='flex gap-x-4'>
            {footer.social.map((item, index) => {
              return (
                <div
                  className='w-12 h-12 text-2xl bg-purple-500 hover:bg-purple-600 rounded-full flex justify-center items-center transition-all'
                  key={index}
                >
                  <a href='/*'>{item.icon}</a>
                </div>
              );
            })}
          </div>
        </div>
        <p className='text-center'>
          &copy; MiniPopStars 2023 - All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
