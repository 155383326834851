import React from 'react';

// import components
import Navbar from '../../components/Navbar';
import Hero from '../../components/Hero';
import Features from '../../components/Features';
import NewItems from '../../components/NewItems';
import Live from '../../components/Live';
import Newsletter from '../../components/Newsletter';
import Founder from '../../components/Founder';
import Footer from '../../components/Footer';


const Index = () => {
    return (
        <div className='w-full mx-auto bg-white'>
            <Navbar />
            <Newsletter />
            <Hero />
            <Features />
            <Live />
            <NewItems />
            <Founder />
            <Footer />
        </div>
    );
};

export default Index;
