import React from 'react';
import Auditions from '../assets/img/AUDITIONS.jpg'

const Hero = () => {
  return (
    <div className='flex justify-center items-center'>
      <a href="/Details">
        <img
          className='w-[1230px]'
          src={Auditions} />
      </a>
    </div>
  );
};

export default Hero;
