import { Fragment, useState } from 'react'
import Logo from '../assets/mpslogo.png'


export default function Example() {
  const [open, setOpen] = useState(false)

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <header className="relative bg-white">
        <p className="flex h-10 items-center justify-center bg-purple-400 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          The #1 Professional Entertainment Group for kids in SA        </p>
        <nav aria-label="Top" className="mx-auto h-[150px] max-w-7xl px-4 sm:px-6 lg:px-8">
          <div>
            <div className="flex h-16 justify-center items-center">

              {/* Logo */}
              <div className="flex items-center justify-center lg:ml-0">
                <a href="/">
                  <img
                    className="flex items-center justify-center w-[250px]  mt-[100px]"
                    src={Logo}
                  />
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}