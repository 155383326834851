// import icons
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoMdAddCircle,
  IoIosCheckmarkCircle,
  IoIosArrowRoundForward,
  IoLogoTwitter,
} from 'react-icons/io';

// import images
import Picture from './assets/img/picture.jpg';
import Dancing from './assets/img/dancing.jpg';
import Ballet from './assets/img/ballet.jpg';
import Kids from './assets/img/kids.jpg';
import Outside from './assets/img/outside.jpg';
import Pop from './assets/img/pop.jpg';

export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'features',
    href: 'features',
  },
  {
    name: 'contact',
    href: 'contact',
  },
];



export const features = {
  image: <Picture />,
  title: 'REQUIREMENTS',
  subtitle:
    'Selected hopefuls will form a group that represents the brand in Productions, Events, Commercials, Music videos, Interviews, live touring shows/appearances, and so much more!    ',
  buttonText: 'Show Now',
  items: [
    {
      icon: <IoIosCheckmarkCircle />,
      title: 'We are looking for boys and girls (ages 7-13), all ethnicities, who can SING and DANCE',

    },
    {
      icon: <IoIosCheckmarkCircle />,
      title: 'looking for a pop sound / Hip Hop style movement (acro / breakdance tricks are a plus). ',

    },
  ],
};

export const newInStore = {
  // title: 'New In Store Now',
  subtitle: 'This is a one year long (or possibly more) program where the selected talent learns everything that it takes to be a pop star. All rehearsals take place in the Western Cape.Auditions will only be taking place at various Malls in the Western Cape.It’s all about having fun with your auditions.We wish everyone the best of luck!',
  // link: 'Gallery',
  icon: <IoIosArrowRoundForward />,
  products: [
    {
      image: <Kids />,
    },
    {
      image: <Outside />,
    },
    {
      image: <Ballet />,
    },
    {
      image: <Pop />,
    },
  ],
};




export const newsletter = {
  title: 'Become part of the team',
  // subtitle: 'Join our mailing list',
  placeholder: 'Your email address',
  buttonText: 'Audition now',
};

export const footer = {
  social: [
    {
      icon: <IoLogoTwitter />,
      href: '/*',
    },
    {
      icon: <IoLogoInstagram />,
      href: '/*',
    },
    {
      icon: <IoLogoFacebook />,
      href: '/*',
    },
  ],
  copyright: 'FurniShop 2022 - All Rights Reserved.',
};
