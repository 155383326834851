import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from "./views/NotFound";
import Details from './forms/details';
import Home from './views/Home/index';


const App = () => {
  return (
    <div className='w-full mx-auto bg-white'>
      <Routes>
        <Route exact path={'/'} element={<Home />} />
        <Route path="/details" element={<Details />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
