import { useLottie } from "lottie-react";
import Lama from '../assets/lama-yoga.json'

const Loading = () => {
  return (
    <div className="h-96 mt-10 flex items-center justify-center">
      <div className=" w-96 text-xl text-slate-700 text-center p-8">
        <h1 className="animate-pulse font-semibold">Loading...</h1>
      </div>
    </div>
  );
};

const Notfound = () => {
  const options = {
    animationData: Lama,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return (
    <div className="h-screen bg-sidebar transition-all  duration-75 delay75 flex items-center justify-center bg-black">
      <div className="text-center">
        <div className="mx-auto mb-2.5 pb-5 font text-4xl font-bold tracking-tight text-purple-400 sm:text-6xl"  >
          MINIPOPSTARS
        </div>
        <h1 className=" text-7xl text-yellow-200 font-medium">404</h1>
        <div className="h-56 w-56 mt-7 mb-5 mx-auto">{View}</div>
        <div className="w-56 mb-5 mx-auto">
          <h1 className="text-xl text-white font-medium">
            So that one kinda snuck  up on you. There, there! Keep calm and
          </h1>
        </div>
        <div className="">
          <a href="/" className="rounded-sm bg-midtoneGreen hover:shadow-md text-purple-400 text-xl font-semibold py-2.5 px-10">
            GO HERE
          </a>
        </div>
      </div>
    </div>
  );
};

export { Loading, Notfound };
