import React from 'react';
import FounderImg from '../assets/img/founder.jpg';

const Founder = () => {
    return (
        <div>
            <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <div className='flex justify-center items-center text-4xl font-bold text-purple-500 text-center'>
                        Founder of Minipopstars
                    </div>
                    <figure className="mt-10">
                        <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                            <p>
                                1. Waterfront Theatre Graduate<br />
                                2. Choreographer/Producer<br />
                                3. Events Co-ordinator<br />
                            </p>
                        </blockquote>
                        <figcaption className="mt-10">
                            <img
                                className="mx-auto h-20 w-20 rounded-full"
                                src={FounderImg}
                                alt="founder"
                            />
                            <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                                <div className="font-semibold text-gray-900">Joeline Kingsley</div>
                                <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                                    <circle cx={1} cy={1} r={1} />
                                </svg>
                                <div className="text-gray-600">Founder(Artistic Director)</div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
            </section>
        </div>
    )
}

export default Founder